
export default {
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      likes: null,
    }
  },
  async fetch () {
    try {
      if (this.id) {
        const {
          data: {
            item: {
              likes = 0,
            } = {},
          } = {},
        } = await this.$api.articles.getAllNested(
          "likes",
          this.id,
        )

        this.likes = likes || null
      }
    } catch(error) {
      this.$log.warn(error)
    }
  },
  computed: {
    cardProps () {
      return {
        'content-type': this.contentType,
        image: this.image,
        imageSize: this.imageSize,
        subtitle: this.subtitle,
        title: this.title,
        titleSize: this.titleSize,
        to: this.to,
      }
    },
    contentType () {
      return this.publication?.type
    },
    id () {
      return this.publication?.reference?.id || this.publication.id
    },
    image () {
      return this.publication?.hero
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374x234 sm:608x380 md:565x353 lg:400x250'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === ''
    },
    subtitle () {
      return this.isSmall ? this.publication?.subtitle : null
    },
    title () {
      return this.publication?.title
    },
    titleSize () {
      return this.isSmall ? 'sm' : 'md'
    },
    to () {
      return this.$link.publication(this.publication)
    },
  },
}
